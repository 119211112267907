<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Data User Role'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12" class="mb-2">
              <b-button variant="outline-dark" @click="$router.push('/user/adduserrole')">
                <span class="svg-icon svg-icon-sm svg-icon-dark">
                  <i class="flaticon2-plus"></i>
                </span>
                Create
              </b-button>
            </b-col>
            <b-col md="12">
              <b-table
                head-variant="dark"
                bordered
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                stacked="lg"
                show-empty
                responsive
                @filtered="onFiltered"
                :busy="isBusy"
              >
                <template #thead-top="data">
                  <b-tr>
                    <b-th colspan="1"></b-th>
                    <b-th>
                      <b-form-input
                        id="filter-1"
                        size="sm"
                        @input="loadData"
                        v-model="search.nama_role"
                        type="search"
                        placeholder="Cari Nama Role..."
                      ></b-form-input>
                    </b-th>
                    <b-th colspan="1"></b-th>
                  </b-tr>
                </template>

                <template #cell(no)="data">
                  {{ (currentPage - 1) * 10 + data.index + 1 }}
                </template>

                <template #cell(actions)="data">
                  <b-dropdown text=". . ." variant="danger" size="sm" no-caret>
                    <b-dropdown-item-button @click="data.toggleDetails">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-success">
                          <i class="flaticon-eye text-success"></i>
                        </span>
                      </div>
                      View
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      @click="$router.push('/user/edituserrole/' + data.item.id_role)"
                    >
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-primary">
                          <i class="flaticon2-pen text-primary"></i>
                        </span>
                      </div>
                      Edit
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="toDelete(data)">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-danger">
                          <i class="flaticon2-trash text-danger"></i>
                        </span>
                      </div>
                      Delete
                    </b-dropdown-item-button>
                  </b-dropdown>
                </template>

                <template #row-details="data">
                  <b-card>
                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"><b>Nama Role :</b></b-col>
                      <b-col>{{ data.item.nama_role }}</b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"><b>Fitur :</b></b-col>
                      <b-col>
                        <ul>
                          <li v-for="(row, index) in JSON.parse(data.item.fitur)" :key="index">
                            {{ row }}
                          </li>
                        </ul>
                      </b-col>
                    </b-row>

                    <span class="svg-icon svg-icon-sm svg-icon-dark" @click="data.toggleDetails">
                      <i class="flaticon-close text-dark"></i>
                    </span>
                  </b-card>
                </template>

                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col md="5">
              <b-pagination
                v-model="currentPage"
                @input="paginate"
                :total-rows="totalRows"
                :per-page="10"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
            <b-col md="5">
              <h4 class="float-right">
                Showing
                <span v-if="totalPerPage">1-{{ totalPerPage }}</span>
                <span v-else>0</span>

                of {{ totalRows }}
              </h4>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data() {
    return {
      items: [],
      fields: [
        { key: 'no', label: 'No', sortable: true },
        { key: 'nama_role', label: 'Nama Role', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      search: {
        nama_role: '',
      },
      totalRows: 0,
      totalPerPage: 0,
      currentPage: 1,

      isBusy: false,
      dialog: false,
    }
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'User', route: '' }, { title: 'Role' }])
  },
  created() {
    var self = this
    ApiService.get('/user/role?page=' + this.currentPage + '&nama_role=' + this.search.nama_role)
      .then(({ data }) => {
        if (data.status == 'ok') {
          self.items = data.data.data
          self.totalRows = data.data.total
          self.totalPerPage = data.data.data.length
          self.isBusy = false
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.loadData()
    },
    paginate() {
      this.loadData()
    },
    async loadData() {
      var self = this
      self.isBusy = true

      await this.timeout(100)
      ApiService.get('/user/role?page=' + this.currentPage + '&nama_role=' + this.search.nama_role)
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.items = data.data.data
            self.totalPerPage = data.data.data.length
            self.isBusy = false
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    toDelete(row) {
      var id = row.item.id_role
      let self = this
      this.$confirm({
        auth: false,
        message: 'Anda yakin akan menghapus data tersebut?',
        button: {
          yes: 'Ya',
          no: 'Batal',
        },
        callback: confirm => {
          if (confirm) {
            ApiService.get('/user/role/delete/' + id).then(({ data }) => {
              if (data.status == 'ok') {
                self.isBusy = true
                var interval1 = null
                var i = 0
                self.loadData()
                interval1 = setInterval(function() {
                  if (i == 0) {
                    self.totalRows--
                    self.makeToast('warning', 'Data berhasil dihapus')
                    self.isBusy = false
                  } else {
                    clearInterval(interval1)
                  }
                  i++
                }, 1000)
              }
            })
          }
        },
      })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
}
</script>
